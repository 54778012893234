import { UserRepository } from '@/repository';
import { DataService } from '@/utils';

const UserController = { 

    formPostPrepend: 'tx_wwgrapp_grapp[user]',

    async changePassword(formData) {
        formData = DataService.buildPostData(formData, this.formPostPrepend);
        const response = await UserRepository.postChangePassword(formData);
        return response;
    },
       
}

export default UserController;
<template>
      <div class="file-list__item" :class="downloadedClass">
        <p class="file-list__file"><a @click="handleClick" :href="file.src" target="_blank" :title="file.identifier"><i class="svgicon svgicon--view" v-html="icons.download"></i>{{ downloadName }}</a></p>
        <p class="created"><i class="svgicon svgicon--created" v-html="icons.created"></i>{{ downloadCreated }}</p>
        <p class="downloaded"><i class="svgicon svgicon--downloaded" v-html="icons.view"></i>{{ downloadTime }}</p>
      </div>
</template>

<script>
import moment from 'moment';

moment.locale("de-at");

export default {
  name: "DownloadItem",
  components: {
  },
  data() {
    return {
      icons: {
        created: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 176.2 176.2" enable-background="new 0 0 176.2 176.2" xml:space="preserve"><g><circle fill="none" stroke="#000000" stroke-width="11.25" stroke-linecap="square" stroke-miterlimit="10" cx="88.1" cy="88.1" r="82.5"/><polyline fill="none" stroke="#000000" stroke-width="11.25" stroke-linecap="square" stroke-miterlimit="10" points="88.1,43.1 88.1,88.1 118.1,88.1 	"/></g></svg>',
        download: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 176.2 148.6" enable-background="new 0 0 176.2 148.6" xml:space="preserve"><g><polyline fill="none" stroke="#000000" stroke-width="11.25" stroke-linecap="square" stroke-miterlimit="10" points="110.6,118.1 88.1,140.6 65.6,118.1 	"/><line fill="none" stroke="#000000" stroke-width="11.25" stroke-linecap="square" stroke-miterlimit="10" x1="88.1" y1="95.6" x2="88.1" y2="50.6"/>	<line fill="none" stroke="#000000" stroke-width="11.25" stroke-miterlimit="10" x1="88.1" y1="140.6" x2="88.1" y2="95.6"/><path fill="none" stroke="#000000" stroke-width="11.25" stroke-linecap="square" stroke-linejoin="bevel" stroke-miterlimit="10" d="		M118.1,95.6h22.5c16.6,0,30-13.4,30-30s-13.4-30-30-30c-3.4,0-6.7,0.7-9.7,1.7C125.2,19,108.3,5.6,88.1,5.6c-24.9,0-45,20.1-45,45		c0,2.2,0.3,4.3,0.6,6.4c-4-3.9-9.6-6.4-15.6-6.4c-12.4,0-22.5,10.1-22.5,22.5s10.1,22.5,22.5,22.5h30"/></g></svg>',
        view: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 178.3 116.2" enable-background="new 0 0 178.3 116.2" xml:space="preserve"><g><circle fill="none" stroke="#000000" stroke-width="11.25" stroke-linecap="square" stroke-miterlimit="10" cx="89.1" cy="58.1" r="30"/>	<path fill="none" stroke="#000000" stroke-width="11.25" stroke-linecap="square" stroke-miterlimit="10" d="M89.1,110.6		c36.6,0,58.6-14.5,82.5-52.5c-23.9-38-45.9-52.5-82.5-52.5"/>	<path fill="none" stroke="#000000" stroke-width="11.25" stroke-linecap="square" stroke-miterlimit="10" d="M89.1,110.6	c-36.6,0-58.6-14.5-82.5-52.5c23.9-38,45.9-52.5,82.5-52.5"/></g></svg>'
      }
    }
  },
  props: {
    file: {
      type: Object,
      default: null
    },
  },
  computed: {
    downloadName() {
      return (this.file.title?this.file.title+(this.file.downloadName?' (' + this.file.downloadName + ')':''):this.file.downloadName?this.file.downloadName:this.file.identifier);
    },
    downloadedClass() {
      return this.file.downloaded?'is-downloaded':'';
    },
    downloadTime() {
      return this.file.downloaded?moment.unix(this.file.downloaded).format('DD.MM.YYYY HH:mm'):"-";
    },
    downloadCreated() {
      return moment.unix(this.file.created).format('DD.MM.YYYY');
    }
  },
  methods: {
    handleClick() {
      this.$emit('onClick', this.file);
    }
  }
};
</script>

import axios from "axios"; 
import { Api } from "@/api";

const LoginRepository = { 

    /**
     * postLogin
     * 
     * @param {String} postData
     * 
     * @returns {Object} server response
     */    
     async postLogin(postData) {
        try {
            const response = await axios.create({withCredentials: true}).post(Api.BACKEND_URL + Api.LOGIN_ACTION, postData);
            if (response.data.success) {
                return response;
            } else {
                return response;
            }            
        } catch (error) {
            //statement.resetStateValues();
            console.error(error);
            //ErrorHandler.createSystemError(error.message);
            return {};
        }
    },     

    /**
     * postLogout
     * 
     * @param {String} postData
     * 
     * @returns {Object} server response
     */    
     async postLogout(postData) {
        try {
            const response = await axios.create({withCredentials: true}).post(Api.BACKEND_URL + Api.LOGOUT_ACTION, postData);
            if (response.data.success) {
                return response;
            } else {
                return response;
            }            
        } catch (error) {
            //statement.resetStateValues();
            console.error(error);
            //ErrorHandler.createSystemError(error.message);
            return {};
        }
    },

    /**
     * checkLogin
     * 
     * @returns {Object} server response
     */    
    async checkLogin() {
        try {
            const response = await axios.create({withCredentials: true}).get(Api.BACKEND_URL + Api.CHECKLOGIN_ACTION);
            return response;
        } catch (error) {
            return {};
        }
    },
}

export default LoginRepository;
import { FileRepository } from '@/repository';

const FileController = { 
    
    files: [],
    folders: [],

    /** 
     * getFiles
     *
     * @param {String} root
     * @return {void}
     */
    async getFiles(root) {
        const response = await FileRepository.getFiles(root);
        if (response.data.success) {
            this.files = response.data.files;
            this.folders = response.data.folders;
        }
    },

    /** 
     * getFile
     *
     * @param {Object} file
     * @return {File}
     */
     async getFile(file) {
        const response = await FileRepository.getFile(file.identifier);
        if (response.data.success) {
            if (response.data.files[0]) {
                this.updateFile(response.data.files[0]);
            }
        }
        return response;
    },

    /**
     * updateFile
     * 
     * @param {Object} newFile
     * @return {void}
     */
    updateFile(newFile) {
        const orig = this.files.filter(f => f.uid == newFile.uid);
        if (orig.length) {
            orig[0].downloaded = newFile.downloaded;
        }
    }

}

export default FileController;
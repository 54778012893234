
import axios from "axios"; 
import { Api } from "@/api";

const FileRepository = { 

    /**
     * getFiles
     * 
     * @param {String} $root
     * 
     * @returns {Object} server response
     */    
     async getFiles($root) {
        try {
            const response = await axios.create({withCredentials: true}).get(Api.BACKEND_URL + Api.FILELIST_ACTION + "&tx_wwgrapp_grapp[root]=" + $root);
            if (response.data.success) {
                return response;
            } else {
                return response;
            }            
        } catch (error) {
            return {};
        }
    },        

    /**
     * getFile
     * 
     * @param {String} $file
     * 
     * @returns {Object} server response
     */    
     async getFile($file) {
        try {
            const response = await axios.create({withCredentials: true}).get(Api.BACKEND_URL + Api.GETFILE_ACTION + "&tx_wwgrapp_grapp[file]=" + $file);
            if (response.data.success) {
                return response;
            } else {
                return response;
            }            
        } catch (error) {
            return {};
        }
    },      
}

export default FileRepository;
<template>
  <div class="dates">
    <h1>{{ $t('headlines.dates') }}</h1>
    <DatesCalendar :dateEntries="dates" />
    <DatesList :dateEntries="dates" />
  </div>
</template>

<script>

import { DatesList, DatesCalendar } from "./dates/";
import { DatesController } from '@/controller';

export default {
  name: "Dates",
  components: {
    DatesList,
    DatesCalendar
  },
  data() {
    return {
      settings: [],
      dates: [],
    }
  },
  created() {
    this.getDates();
  },
  methods: {
    async getDates() {
      await DatesController.getDates();
      this.dates = DatesController.dates;
    }
  },
};
</script>

<template>
  <div class="wrapper-app">
    <header class="app-header">
      <div class="app-header__inner">
        <a href="/" class="logo">
          <img alt="Marktgmeinde Nußdorf-Debant" src="../../assets/img/marktgemeinde-nussdorf-debant.svg" />
          <p>Marktgemeinde<br>Nussdorf-Debant</p>
        </a>
        <HeaderNav @onUser="handleUserSettings" />
      </div>
    </header>
    <main class="appmain" >
      <router-view :key="key" />
    </main>  
  </div>
</template>

<script>
import { HeaderNav } from "@/components/";

export default {
  name: "Layout",
  components: {
    HeaderNav
  },
  data() {
    return {
      showUserSettings: false
    };
  },
  computed: {
    key() {
      return this.$route.fullPath;
    },
  },
  created() {
  },
  mounted() {
  },
  methods: {
    handleUserSettings() {
      this.showUserSettings = true;
    }
  }
};
</script>

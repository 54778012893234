<template>
  <div class="loginbox">
    <div class="loginbox__inner">
      <header class="loginbox__header">
        <h2>{{ $t('headlines.login') }}</h2>
      </header>
      <form @submit.prevent="submit" novalidate class="form--login">
        <div class="form__item form__item--login">
          <p class="form__error" v-if="loginError">{{ $t('error.login.invalid') }}</p>
        </div>
        <div class="form__item form__item--login">
          <label for="user">{{ $t('fields.label.username') }}*</label>
          <input :placeholder="$t('fields.placeholder.username')" autocomplete="off" type="email" class="form-control" id="user" v-model.trim="form.user" />
        </div>
        <div class="form__item form__item--login">
          <label for="pass">{{ $t('fields.label.password') }}*</label>
          <input :placeholder="$t('fields.placeholder.password')" autocomplete="off" type="password" class="form-control" id="pass" v-model.trim="form.pass" />
        </div>
        <div class="form__item form__item--login is--hidden">
          <input type="hidden" class="form-control is--hidden" id="logintype" v-model.trim="form.loginType"
        />
          <input type="hidden" class="form-control is--hidden" id="pid" v-model.trim="form.pid" />
        </div>
        <div class="form__item form__item--login form__item--action">
          <button @click.prevent="handleSubmit" type="primary" class="button button--primary" v-if="!submitting">
            {{ $t('buttons.login') }}
          </button>
          <div class="login__loading" v-else>
            <span>{{ $t('messages.dologin') }}</span>
            <img src="../../assets/img/loader.svg" />
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { LoginController } from "@/controller";

export default {
  name: "FormLogin",
  data() {
    return {
      loginError: false,
      submitting: false,
      form: {
        userId: "",
        pass: "",
        logintype: "login",
        pid: 0
      },
    };
  },
  computed: {
  },
  props: {
    settings: {
      type: Object,
      default: null
    }
  },
  watch: {
    settings: function() {
      this.form.pid = this.settings.loginPid;
    }
  },
  mounted() {},
  methods: {
    async handleSubmit() {
      this.submitting = true;
      const response = await LoginController.login(this.form);
      if (response.data.success) {
        this.loginError = false;
        this.$emit("onSubmit", response);
      } else {
        this.loginError = true;
      }
      this.submitting = false;
    },
    submit() {
    },
    send() {
    },
  }
};
</script>

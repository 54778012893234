<template>
  <div class="view-selection">
    <div class="buttongroup">
      <button class="button button--view" :class="{'is-active': view=='files'}" @click="handleViewClick('files')"><i class="svgicon svgicon--files" v-html="icons.files"></i>{{ $t('buttons.files') }}</button>
      <button class="button button--view" :class="{'is-active': view=='dates'}" @click="handleViewClick('dates')"><i class="svgicon svgicon--files" v-html="icons.dates"></i>{{ $t('buttons.dates') }}</button>
    </div>
  </div>
</template>

<script>

export default {
  name: "ViewSelection",
  components: {
  },
  data() {
    return {
      icons: {
        dates: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 176.2 161.2" enable-background="new 0 0 176.2 161.2" xml:space="preserve"><g><path fill="none" stroke="#000000" stroke-width="11.25" stroke-linecap="square" stroke-miterlimit="10" d="M5.6,140.6c0,8.3,6.7,15,15,15h135c8.3,0,15-6.7,15-15v-120H5.6V140.6z"/><line fill="none" stroke="#000000" stroke-width="11.25" stroke-linecap="square" stroke-miterlimit="10" x1="5.6" y1="65.6" x2="170.6" y2="65.6"/><line fill="none" stroke="#000000" stroke-width="11.25" stroke-linecap="square" stroke-miterlimit="10" x1="103.1" y1="35.6" x2="103.1" y2="5.6"/><line fill="none" stroke="#000000" stroke-width="11.25" stroke-linecap="square" stroke-miterlimit="10" x1="73.1" y1="35.6" x2="73.1" y2="5.6"/>		<line fill="none" stroke="#000000" stroke-width="11.25" stroke-linecap="square" stroke-miterlimit="10" x1="133.1" y1="35.6" x2="133.1" y2="5.6"/><line fill="none" stroke="#000000" stroke-width="11.25" stroke-linecap="square" stroke-miterlimit="10" x1="43.1" y1="35.6" x2="43.1" y2="5.6"/></g></svg>',
        files: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 146.2 176.2" enable-background="new 0 0 146.2 176.2" xml:space="preserve"><g><polyline fill="none" stroke="#000000" stroke-width="11.25" stroke-miterlimit="10" points="5.6,125.6 50.6,125.6 50.6,170.6 	"/><polygon fill="none" stroke="#000000" stroke-width="11.25" stroke-linecap="square" stroke-miterlimit="10" points="140.6,170.6 140.6,5.6 5.6,5.6 5.6,125.6 50.6,170.6 	"/><line fill="none" stroke="#000000" stroke-width="11.25" stroke-linecap="square" stroke-miterlimit="10" x1="35.6" y1="65.6" x2="65.6" y2="65.6"/><line fill="none" stroke="#000000" stroke-width="11.25" stroke-linecap="square" stroke-miterlimit="10" x1="95.6" y1="65.6" x2="110.6" y2="65.6"/><line fill="none" stroke="#000000" stroke-width="11.25" stroke-linecap="square" stroke-miterlimit="10" x1="35.6" y1="95.6" x2="110.6" y2="95.6"/>	<line fill="none" stroke="#000000" stroke-width="11.25" stroke-linecap="square" stroke-miterlimit="10" x1="35.6" y1="35.6" x2="110.6" y2="35.6"/></g></svg>'
      },
      view: 'files'
    }
  },
  props: {
  },
  methods: {
    handleViewClick(view) {
      this.view = view;
      this.$emit("onViewChange", view);
    }
  }
};
</script>
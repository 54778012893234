<template>
  <div class="wrapper-dateslist">
    <header>
      <h3 class="">{{ $t('headlines.datelist') }}</h3>
    </header>
    <div class="dates-list">
      <DatesListItem 
          v-for="entry in futureEntries"
          :key="entry.uid"
          :bind="entry.uid"
          :dateEntry="entry"
        />
    </div>
  </div>
</template>

<script>
import DatesListItem from './datesList/datesListItem.vue';

export default {
  name: "DatesList",
  components: {
    DatesListItem
  },
  data() {
    return {
    }
  },
  computed: {
    futureEntries() {
      const d = Date.now();
      return this.dateEntries.filter(entry => {
        return entry.beginDate*1000 >= d;
      });
    }
  },
  props: {
    dateEntries: {
      type: Array,
      default: null
    },
  },
  mounted() {
    console.log('dateEntries', this.dateEntries);
  },
  methods: {

  }
};
</script>
import { LoginRepository } from '@/repository';
import { DataService } from '@/utils';

const LoginController = { 

    loginOk: false,
    userId: 0,

    async login(formData) {
        formData = DataService.stringify(formData);
        const response = await LoginRepository.postLogin(formData);
        if (response) {
            if (response.data.success) {
                this.loginOk = true;
                this.userId = response.data.user.uid;
            } else {
                this.loginOk = false;
                this.userId = 0;
            }
            return response;
        }
    },

    async logout() {
        const formData = 'logintype=logout';
        const response = await LoginRepository.postLogout(formData);
        if (response) {
            if (response.data.success) {
                this.loginOk = false;
                this.userId = 0;
            } else {
                this.loginOk = false;
                this.userId = 0;
            }
            return response;
        }
    },

    async checkLogin() {
        const response = await LoginRepository.checkLogin();
        if (response) {
            if (response.data.success) {
                this.loginOk = true;
                this.userId = response.data.user.uid;
            } else {
                this.loginOk = false;
                this.userId = 0;
            }
            return response;
        }
    },

    async getImages() {
        
        const response = await LoginRepository.getImages();
        if (response.data.success) {
            this.images = response.data.images;
            return response;
        } else {
            return response;
        }
        
    }       
}

export default LoginController;
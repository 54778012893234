
import axios from "axios"; 
import { Api } from "@/api";

const UserRepository = { 

    /**
     * postNewDownloadForm
     * 
     * @param {String} postData
     * 
     * @returns {Object} server response
     */    
     async postChangePassword(postData) {
        try {
            const response = await axios.create({withCredentials: true}).post(Api.BACKEND_URL + Api.CHANGEPASSWORD_ACTION, postData);
            return response;
        } catch (error) {
            //statement.resetStateValues();
            console.error(error);
            //ErrorHandler.createSystemError(error.message);
            return {};
        }
    },     


}

export default UserRepository;
<template>
  <div class="wrapper-folderlist">
    <header>
      <h3 class="">{{ $t('headlines.folders') }}</h3>
    </header>
    <div class="folder-list">
      <div 
          v-for="folder in folders"
          :key="folder.uid"
          :bind="folder"
        >
        <button class="button button--folder" @click.prevent="handleFolderClick(folder)" :class="{'has-newfiles': hasNewFiles(folder)}"><i class="svgicon svgicon--folder" v-html="icons.folder"></i>{{ folder.name }} {{ newFilesString(folder) }}</button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "FolderList",
  components: {
  },
  data() {
    return {
      icons: {
        folder: '<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"	 viewBox="0 0 176.2 146.2" enable-background="new 0 0 176.2 146.2" xml:space="preserve"><g><path fill="none" stroke="#000000" stroke-width="11.25" stroke-linecap="square" stroke-miterlimit="10" d="M20.6,5.6		c-8.3,0-15,6.7-15,15v105c0,8.3,6.7,15,15,15h135c8.3,0,15-6.7,15-15v-90c0-8.3-6.7-15-15-15h-75l-15-15H20.6z"/></g></svg>'
      }
    }
  },
  props: {
    folders: {
      type: Array,
      default: null
    }
  },
  methods: {
    handleFolderClick(folder) {
      this.$emit("onFolderClick", folder);
    },
    hasNewFiles(item) {
      return item.newsubfiles > 0;
    },
    newFilesString(item) {
      return (item.newsubfiles > 0 ? "(" + item.newsubfiles + ")" : "");
    }
  }
};
</script>
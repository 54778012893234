
import axios from "axios"; 
import { Api } from "@/api";

const SettingsRepository = { 

    /**
     * getSettings
     *
     * @returns {Object} server response
     */    
    async getSettings() {
        try {
            const response = await axios.create({withCredentials: true}).get(Api.BACKEND_URL + Api.GETSETTINGS_ACTION);
            if (response.data.success) {
                return response;
            } else {
                return response;
            }            
        } catch (error) {
            //statement.resetStateValues();
            //console.error(error);
            //ErrorHandler.createSystemError(error.message);
            return {};
        }
    },
}

export default SettingsRepository;
<template>
      <div class="dates-list__item">
        <p class="dates__begindate"><i class="svgicon svgicon--date" v-html="icons.date"></i>{{ beginDate }} {{ beginTime }}</p>
        <p class="dates__title">{{ dateEntry.title }}</p>
      </div>
</template>

<script>
import moment from 'moment-timezone';

moment.locale("de-at");
moment.tz.setDefault("Europe/Berlin");

export default {
  name: "DatesListItem",
  components: {
  },
  data() {
    return {
      icons: {
        time: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 176.2 176.2" enable-background="new 0 0 176.2 176.2" xml:space="preserve"><g><circle fill="none" stroke="#000000" stroke-width="11.25" stroke-linecap="square" stroke-miterlimit="10" cx="88.1" cy="88.1" r="82.5"/><polyline fill="none" stroke="#000000" stroke-width="11.25" stroke-linecap="square" stroke-miterlimit="10" points="88.1,43.1 88.1,88.1 118.1,88.1 	"/></g></svg>',
        date: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 176.2 161.2" enable-background="new 0 0 176.2 161.2" xml:space="preserve"><g><path fill="none" stroke="#000000" stroke-width="11.25" stroke-linecap="square" stroke-miterlimit="10" d="M5.6,140.6c0,8.3,6.7,15,15,15h135c8.3,0,15-6.7,15-15v-120H5.6V140.6z"/><line fill="none" stroke="#000000" stroke-width="11.25" stroke-linecap="square" stroke-miterlimit="10" x1="5.6" y1="65.6" x2="170.6" y2="65.6"/><line fill="none" stroke="#000000" stroke-width="11.25" stroke-linecap="square" stroke-miterlimit="10" x1="103.1" y1="35.6" x2="103.1" y2="5.6"/><line fill="none" stroke="#000000" stroke-width="11.25" stroke-linecap="square" stroke-miterlimit="10" x1="73.1" y1="35.6" x2="73.1" y2="5.6"/>		<line fill="none" stroke="#000000" stroke-width="11.25" stroke-linecap="square" stroke-miterlimit="10" x1="133.1" y1="35.6" x2="133.1" y2="5.6"/><line fill="none" stroke="#000000" stroke-width="11.25" stroke-linecap="square" stroke-miterlimit="10" x1="43.1" y1="35.6" x2="43.1" y2="5.6"/></g></svg>',
      }
    }
  },
  props: {
    dateEntry: {
      type: Object,
      default: null
    },
  },
  computed: {
    beginDate() {
      return moment.unix(this.dateEntry.beginDate).format("DD.MM.YYYY"); // + (this.dateEntry.beginTime?'<span><i class="svgicon svgicon--time">' + this.icons.time + '</i>' + this.dateEntry.beginTime + '</span>':"");
    },
    beginTime() {
      return this.dateEntry.beginTime?moment.unix(this.dateEntry.beginTime-3600).format("HH:mm"):'';
    }
  },
  methods: {
    handleClick() {
      this.$emit('onClick', this.file);
    }
  }
};
</script>
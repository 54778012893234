<template>
  <div class="content-welcome">
    <h1>{{ $t('headlines.welcome') }}</h1>
    <p> {{ $t('messages.enter_login') }}</p>
  </div>
</template>

<script>
export default {
  name: "Welcome",
  props: {
  },
};
</script>
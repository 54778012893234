<template>
  <div class="change-password-box">
    <div class="change-password-box__inner">
      <header class="change-password-box__header">
        <h2>{{ $t('headlines.change_password') }}</h2>
      </header>
      <form @submit.prevent="submit" novalidate class="form--change-password">
        <div class="form__item form__item--change-password">
          <p class="form__error" v-if="error">{{ $t('label.error') }}: {{ $t(errorMessage) }}</p>
        </div>
        <div class="form__item form__item--change-password-repeat-current">
          <label for="pass">{{ $t('fields.label.password_current') }}*</label>
          <input :placeholder="$t('fields.placeholder.password_current')" autocomplete="off" type="password" class="form-control" id="passwordCurrent" v-model.trim="form.passwordCurrent" />
        </div>         
        <div class="form__item form__item--change-password">
          <label for="pass">{{ $t('fields.label.password_new') }}*</label>
          <input :placeholder="$t('fields.placeholder.password_new')" autocomplete="off" type="password" class="form-control" id="password" v-model.trim="form.password" />
        </div>
        <div class="form__item form__item--change-password-repeat">
          <label for="pass">{{ $t('fields.label.password_repeat') }}*</label>
          <input :placeholder="$t('fields.placeholder.password_repeat')" autocomplete="off" type="password" class="form-control" id="passwordRepeat" v-model.trim="form.passwordRepeat" />
        </div>        
        <div class="form__item form__item--change-password is--hidden">
          <input type="hidden" class="form-control is--hidden" id="userId" v-model="form.userId" />
          <input type="hidden" class="form-control is--hidden" id="pid" v-model="form.pid" />
        </div>
        <div class="form__item form__item--change-password" v-if="success">
          <p class="form__success">{{ $t('messages.changepassword_success') }}</p>
        </div>
        <div class="form__item form__item--change-password form__item--action">
          <div class="button-wrapper">
            <button @click.prevent="handleSubmit" type="primary" class="button button--primary" v-if="!submitting">
              {{ $t('buttons.change_password') }}
            </button>
            <div class="login__loading" v-else>
              <span>{{ $t('messages.dologin') }}</span>
              <img src="../../assets/img/loader.svg" />
            </div>
          </div>
          <button @click.prevent="handleClose" type="secondary" class="button button--dense" v-if="!submitting">
            {{ $t('buttons.close') }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { UserController, LoginController } from "@/controller";

export default {
  name: "ChangePassword",
  data() {
    return {
      error: false,
      success: false,
      submitting: false,
      form: {
        userId: 0,
        password: "",
        passwordRepeat: "",
        passwordCurrent: "",
        pid: 0
      },
      errorMessage: "",
    };
  },
  computed: {
  },
  props: {
    settings: {
      type: Object,
      default: null
    },
    userId: {
      type: Number,
      default: 0
    }
  },
  watch: {
    settings: function() {
      this.form.pid = this.settings.loginPid;
    },
    userId: function(val) {
      this.form.userId = val;
    }
  },
  mounted() {
    this.form.userId = LoginController.userId;
  },
  methods: {
    async handleSubmit() {
      this.submitting = true;
      const response = await UserController.changePassword(this.form);
      if (response.data.success) {
        this.success = true;
        this.error = false;
      } else {
        this.success = false;
        this.error = true;
        this.errorMessage = response.data.errors[0].message;
      }
      this.submitting = false;
    },
    handleClose() {
      this.$emit("onClose");
    },
    submit() {
    },
    send() {
    },
  }
};
</script>

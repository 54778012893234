const DataService = { 

    buildPostData(data, prefix, useValue) {
        var postData = "";
        for (var key in data) {
          if (key !== "uid" && data[key] != 0) {
            postData += (postData.length ? "&" : "") + "[" + key + "]" + "=" +
              encodeURIComponent(useValue?data[key]['value']:data[key]);
          }
        }
      
        //postData = postData.replace(/=/g, "]=").replace(/\&/g, "&" + prefix);
        /* eslint-disable-next-line */
        postData = postData.replace(/\&/g, "&" + prefix);
        postData = prefix + postData;
        if (postData.indexOf("[uid]")) {
          //replace uid with __identity for property mapping in backend
          postData = postData.replace(/\[uid\]/, "[__identity]");
        }
        return postData;
      },

      stringify(data) {
        var postData = "";
          for (var key in data) {
              postData += (postData.length ? "&" : "") + key + "=" + (data[key]?encodeURIComponent(data[key]):'');
              //postData += (postData.length ? "&" : "") + key + "=" + data[key];
          }
          return postData;
      }
}

export default DataService;
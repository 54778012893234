<template>
  <div class="wrapper-filelist">
    <header>
      <h3 class="">{{ $t('headlines.files') }}</h3>
    </header>
    <div class="file-list">
      <DownloadItem 
          v-for="(file, index) in files"
          :key="index"
          :bind="file.uid"
          :file="file"
          @onClick="handleFileDownloadClick"
        />
    </div>
  </div>
</template>

<script>
import DownloadItem from './fileList/downloadItem.vue';

export default {
  name: "FileList",
  components: {
    DownloadItem
  },
  data() {
    return {
    }
  },
  props: {
    files: {
      type: Array,
      default: null
    },
  },
  methods: {
    handleFileDownloadClick(file) {
      this.$emit("onOpenFile", file);
    }
  }
};
</script>
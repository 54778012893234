<template>
  <div class="wrapper-folder-overview">
      <p class="property"><span class="property__label">{{ $t('messages.current_folder') }}:</span><span class="property__value">{{ (selectedFolder?selectedFolder.name:'/') }}</span></p>
      <div class="row">
        <button class="button button--home button--icon" @click.prevent="handleHomeClick"><i class="svgicon svgicon--home" v-html="icons.home"></i></button>
        <button v-if="selectedFolder" class="button button--folder-up button--icon" @click.prevent="handleParentClick"><i class="svgicon svgicon--home" v-html="icons.folderUp"></i></button>
      </div>
  </div>
</template>

<script>

export default {
  name: "FolderOverview",
  components: {
  },
  data() {
    return {
      icons: {
        home: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 177.2 163.6" enable-background="new 0 0 177.2 163.6" xml:space="preserve"><g>	<polyline fill="none" stroke="#000000" stroke-width="11.25" stroke-linecap="square" stroke-miterlimit="10" points="66.1,158 		66.1,98 111.1,98 111.1,158 	"/>	<polyline fill="none" stroke="#000000" stroke-width="11.25" stroke-linecap="square" stroke-miterlimit="10" points="156.1,128 		156.1,83 163.6,83 88.6,8 13.6,83 21.1,83 21.1,128 	"/>	<polyline fill="none" stroke="#000000" stroke-width="11.25" stroke-linecap="square" stroke-miterlimit="10" points="21.1,128 		21.1,158 156.1,158 156.1,128 	"/></g></svg>',
        folderUp: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"viewBox="0 0 176.2 146.2" enable-background="new 0 0 176.2 146.2" xml:space="preserve"><g>	<path fill="none" stroke="#000000" stroke-width="11.25" stroke-linecap="square" stroke-miterlimit="10" d="M20.6,5.6c-8.3,0-15,6.7-15,15v105c0,8.3,6.7,15,15,15h135c8.3,0,15-6.7,15-15v-90c0-8.3-6.7-15-15-15h-75l-15-15H20.6z"/></g><line fill="none" stroke="#000000" stroke-width="7.9124" stroke-linecap="square" stroke-miterlimit="10" x1="54.1" y1="43.4" x2="75.2" y2="64.5"/><line fill="none" stroke="#000000" stroke-width="7.9124" stroke-linecap="square" stroke-miterlimit="10" x1="54.1" y1="85.6" x2="54.1" y2="117.2"/><line fill="none" stroke="#000000" stroke-width="7.9124" stroke-linecap="square" stroke-miterlimit="10" x1="33" y1="64.5" x2="54.1" y2="43.4"/><line fill="none" stroke="#000000" stroke-width="7.9124" stroke-miterlimit="10" x1="54.1" y1="85.6" x2="54.1" y2="43.4"/></svg>'
      }
    }
  },
  props: {
    selectedFolder: {
      type: Object,
      default: null
    }
  },
  methods: {
    handleParentClick() {
      this.$emit("onParentClick");
    },
    handleHomeClick() {
      this.$emit("onHomeClick");
    }
  }
};
</script>
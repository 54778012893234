
import axios from "axios"; 
import { Api } from "@/api";

const DatesRepository = { 

    /**
     * getDates
     * 
     * @returns {Object} server response
     */    
     async getDates() {
        try {
            const response = await axios.create({withCredentials: true}).get(Api.BACKEND_URL + Api.GETDATES_ACTION);
            if (response.data.success) {
                return response;
            } else {
                return response;
            }            
        } catch (error) {
            return {};
        }
    },        
   
}

export default DatesRepository;
<template>
  <nav id="nav">
    <button v-if="loginOk" class="button button--icon button--dense" @click="handleUserClick"><i class="svgicon svgicon--user" v-html="icons.user"></i></button>
    <button v-if="loginOk" class="button button--icon button--dense" @click="handleLogoutClick"><i class="svgicon svgicon--logout" v-html="icons.logout"></i></button>
    <router-link v-if="false" to="/">Home</router-link>
    <router-link v-if="false" to="/about">About</router-link>
  </nav>
</template>

<script>
import { LoginController } from "@/controller/";

export default {
  name: "HeaderNav",
  data() {
    return {
      icons: {
        user: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 176.2 176.2" enable-background="new 0 0 176.2 176.2" xml:space="preserve"><g><circle fill="none" stroke="#000000" stroke-width="11.25" stroke-linecap="square" stroke-miterlimit="10" cx="88.1" cy="88.1" r="82.5"/><circle fill="none" stroke="#000000" stroke-width="11.25" stroke-linecap="square" stroke-miterlimit="10" cx="88.1" cy="65.6" r="30"/><path fill="none" stroke="#000000" stroke-width="11.25" stroke-miterlimit="10" d="M140.6,151.8c-1.4-38.8-24.3-56.2-52.4-56.2c-28.1,0-51,17.4-52.4,56.2"/></g></svg>',
        logout: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 176.2 176.2" enable-background="new 0 0 176.2 176.2" xml:space="preserve"><g><line fill="none" stroke="#000000" stroke-width="11.25" stroke-linecap="square" stroke-miterlimit="10" x1="119.3" y1="88.5" x2="81.8" y2="88.5"/><line fill="none" stroke="#000000" stroke-width="11.25" stroke-miterlimit="10" x1="156.8" y1="88.5" x2="119.3" y2="88.5"/><polyline fill="none" stroke="#000000" stroke-width="11.25" stroke-miterlimit="10" points="126.8,148.5 126.8,171 21.8,171 21.8,6 126.8,6 126.8,28.5 	"/><line fill="none" stroke="#000000" stroke-width="11.25" stroke-linecap="square" stroke-miterlimit="10" x1="126.8" y1="58.5" x2="156.8" y2="88.5"/><line fill="none" stroke="#000000" stroke-width="11.25" stroke-linecap="square" stroke-miterlimit="10" x1="126.8" y1="118.5" x2="156.8" y2="88.5"/></g></svg>'
      },
      loginOk: false
    }
  },
  props: {
  },
  created() {
    this.emitter.on("onLogin", () => {
      this.loginOk = true;
    });
    this.emitter.on("onLogout", () => {
      this.loginOk = false;
    });
  },
  methods: {
    handleUserClick() {
      this.emitter.emit("onUserSettings");
    },
    async handleLogoutClick() {
      await LoginController.logout();
      this.loginOk = false;
      this.emitter.emit("onLogout");
    }
  }
};
</script>
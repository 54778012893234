import { FolderRepository } from '@/repository';

const FolderController = { 
    
    folders: [],
    selected: null,

    /** 
     * getFolders
     *
     * @return {void}
     */
    async getFolders() {
        const response = await FolderRepository.getFolders();
        if (response.data.success) {
            this.folders = response.data.folders;
        }
    },

    getParentFolder() {
        let parent = null;
        if (this.selected) {
            parent = this.filterRecursive(this.folders, parent);
        }
        return parent;
    },

    filterRecursive(folders, found) {
        
        folders.forEach(folder => {
            if (!found) {
                if (folder.uid == this.selected.parentId) {
                    found = folder;
                } else if (folder.subfolders) {
                    found = this.filterRecursive(folder.subfolders);
                }
            }
        });
        
        return found;
    },

    activateParent() {
        this.selected = this.getParentFolder();
    },

    activateHome() {
        this.selected = null;
    },

    /**
     * getFolderIdentifier
     * 
     * @returns String the folder identifier of the selected folder, if not the root
     */
    getFolderIdentifier() {
        if (this.selected) {
            return this.selected.identifier;
        } else {
            return "/";
        }
    },

    getSelectedSubFolders() {
        return this.selected?this.selected.subfolders:this.folders;
    }
}

export default FolderController;
import { DatesRepository } from '@/repository';

const DatesController = { 
    
    dates: [],

    /** 
     * getDates
     *
     * @return {void}
     */
    async getDates() {
        const response = await DatesRepository.getDates();
        if (response.data.success) {
            this.dates = response.data.dates;
        }
    }

}

export default DatesController;
<template>
  <div class="docs">
    <h1>{{ $t('headlines.files') }}</h1>
    <FolderOverview :selectedFolder="selectedFolder" @onParentClick="handleParentClick" @onHomeClick="handleHomeClick" />
    <FolderList :folders="folders" @onFolderClick="handleFolderClick"/>
    <FileList :files="files" @onOpenFile="handleOpenFile"/>
  </div>
</template>

<script>

import { FileList, FolderList, FolderOverview }from "./filesAndFolders/";
import { FileController, FolderController } from '@/controller';

export default {
  name: "FoldersFiles",
  components: {
    FileList,
    FolderList,
    FolderOverview
  },
  data() {
    return {
      settings: [],
      files: [],
      folders: [],
      selectedFolder: null
    }
  },
  created() {
    this.getFoldersAndFiles();
  },
  methods: {
    async getFoldersAndFiles() {
      await this.getFolders();
      this.folders = FolderController.folders;
      this.getFiles(FolderController.getFolderIdentifier());
    },
    handleParentClick() {
      FolderController.activateParent();
      this.selectedFolder = FolderController.selected;
      this.folders = FolderController.getSelectedSubFolders();
      this.getFiles(FolderController.getFolderIdentifier());
    },
    handleHomeClick() {
      FolderController.activateHome();
      this.selectedFolder = FolderController.selected;
      this.folders = FolderController.folders;
      this.getFiles(FolderController.getFolderIdentifier());
    },    
    handleFolderClick(folder) {
      FolderController.selected = folder;
      this.selectedFolder = folder;
      this.folders = FolderController.getSelectedSubFolders();
      this.getFiles(FolderController.getFolderIdentifier());
    },    
    async getFolders() {
      await FolderController.getFolders();
      this.folders = FileController.folders;
    },
    async getFiles(folder) {
      await FileController.getFiles(folder);
      this.files = FileController.files;
    },
    handleOpenFile(file) {
        //wait a little time to let the server save the data
      setTimeout(() => this.getFile(file), 5000);
    },
    async getFile(file) {
      this.files = [];
      await FileController.getFile(file);
        //update local files
      this.files = FileController.files;
    },

  },
};
</script>

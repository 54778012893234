export default {
    de: {
        headlines: {
            welcome: "Willkommen",
            login: "Anmelden",
            folders: "Ordner",
            files: "Dokumente",
            dates: "Termine",
            change_password: "Passwort ändern",
            datelist: "Liste",
            datecalendar: "Kalender"
        },
        label: {
            error: "Fehler"
        },
        messages: {
            enter_login: "Bitte melden Sie sich hier mit Ihren Benutzerdaten an.",
            dologin: "Anmelden ...",
            current_folder: "aktueller Ordner",
            changepassword_success: "Passwort erfolgreich geändert"
        },
        fields: {
            label: {
                username: "Benutzername (email)",
                password: "Passwort",
                password_new: "Neues Passwort",
                password_repeat: "Passwort Wiederholung",
                password_current: "Aktuelles Passwort",
            },
            placeholder: {
                username: "Benutzername eingeben",
                password: "Passwort",
                password_new: "Neues Passwort eingeben",
                password_repeat: "Passwort wiederholen",
                password_current: "Aktuelles Passwort"
            },
        },
        buttons: {
            login: "Anmelden",
            download: "download",
            guidelogin: "Guide",
            backTo: "zurück zu",
            files: "Dokumente",
            dates: "Termine",
            change_password: "Passwort ändern",
            close: "Schliessen"
        },
        error: {
            login: {
                invalid: "Benutzername od. Passwort falsch"
            },
            changepassword: {
                missing: "Angaben unvollständig",
                invalid: "ungültiges Passwort",
                notfound: "Benutzer nicht gefunden"
            }
        }
    }
};
  
<template>
  <div class="wrapper-datescalendar">
    <header>
      <h3 class="">{{ $t('headlines.datecalendar') }}</h3>
    </header>
    <div class="dates-calendar">
      <Calendar 
        :columns="$screens({ default: 1, md: 3, lg: 3 })"
        is-expanded
        :attributes="attributes"
        color="gray"
      />
    </div>
  </div>
</template>

<script>
import { Calendar } from 'v-calendar';

export default {
  name: "DatesCalendar",
  components: {
    Calendar
  },
  data() {
    return {}
  },
  computed: {
    attributes() {
      console.log('this.dateEntries', this.dateEntries);
      return this.dateEntries.map(d => ({
        highlight: {
          color: "gray",
          fillMode: "solid",
          style: {
            borderRadius: "4px",
            backgroundColor: "#000"
          }
        },
        dates: new Date(d.beginDate * 1000),
        popover: {
          label: d.title
        }        
      }));
    }
  },
  props: {
    dateEntries: {
      type: Array,
      default: null
    },
  },
  mounted() {
  },
  methods: {

  }
};
</script>
import { SettingsRepository } from '@/repository';
//import { DataService } from '@/utils';

const SettingsController = { 
    
    settings: [],

    async getSettings() {
        
        const response = await SettingsRepository.getSettings();
        if (response.data.success) {
            this.settings = response.data.settings;
            return response;
        } else {
            return response;
        }
        
    }       
}

export default SettingsController;
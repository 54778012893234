export const Api = Object.freeze({
    BACKEND_URL: process.env.VUE_APP_BACKEND,
    GETSETTINGS_ACTION: '?tx_wwgrapp_grapp[action]=settings',
    LOGIN_ACTION: '?tx_wwgrapp_grapp[action]=login',
    LOGOUT_ACTION: '?tx_wwgrapp_grapp[action]=logout',
    CHANGEPASSWORD_ACTION: '?tx_wwgrapp_grapp[action]=changePassword',
    CHECKLOGIN_ACTION: '?tx_wwgrapp_grapp[action]=checkLogin',
    FILELIST_ACTION: '?tx_wwgrapp_grapp[action]=filelist',
    FOLDERLIST_ACTION: '?tx_wwgrapp_grapp[action]=folderlist',
    GETFILE_ACTION: '?tx_wwgrapp_grapp[action]=getFile',
    GETDATES_ACTION: '?tx_wwgrapp_grapp[action]=dates',
});
  